$(window).on('load', function() {

    $('.loader-mask').fadeOut(800, function() {});

    var wow = new WOW({
        animateClass: 'animated',
        offset: 100,
        callback: function(box) {
            console.log(box.tagName);
        }
    });
    wow.init();
    // new WOW().init();
});

$(document).ready(function() {
    "use strict";

    /*  Menu */
    // slideEffectAjax()
    // jQuery(".toggle").on("click", function() {
    //         return jQuery(".submenu").is(":hidden") ? jQuery(".submenu").slideDown("fast") : jQuery(".submenu").slideUp("fast"), !1
    //     }), jQuery(".topnav").accordion({
    //         accordion: !1,
    //         speed: 300,
    //         closedSign: "+",
    //         openedSign: "-"
    //     }),
        $("#nav > li").hover(function() {
            var e = $(this).find(".level0-wrapper, .main-drop");
            e.hide(), e.css("left", "0"), e.stop(true, true).fadeIn(300, "easeOutCubic")
        }, function() {
            $(this).find(".level0-wrapper, .main-drop").stop(true, true).fadeOut(300, "easeInCubic")
        });
        $(".map-wrapper").on({
            mouseleave: function() {
                $(this).find('iframe').css('pointer-events', 'none');
            },
            click: function() {
                $(this).find('iframe').css('pointer-events', 'auto');
            }
        }).find('iframe').css('pointer-events', 'none');

    // /*Loader*/
    // jQuery(document).ready(function($) {
        
    // });


    /*  Sidebar Menu */
    $("ul.accordion li.parent, ul.accordion li.parents, ul#magicat li.open").each(function() {
        $(this).append('<em class="open-close">&nbsp;</em>')
    }), $("ul.accordion, ul#magicat").accordionNew(), $("ul.accordion li.active, ul#magicat li.active").each(function() {
        $(this).children().next("div").css("display", "block")
    })

    /*  Cart  */
    function deleteCartInCheckoutPage() {
        return $(".checkout-cart-index a.btn-remove2,.checkout-cart-index a.btn-remove").on("click", function(e) {
            return e.preventDefault(), confirm(confirm_content) ? void 0 : !1
        }), !1
    }
    $(".subDropdown")[0] && $(".subDropdown").on("click", function() {
            $(this).toggleClass("plus"), $(this).toggleClass("minus"), $(this).parent().find("ul").slideToggle()
        })
     /*  Top Cart */
     //$('.top-cart-contain').hover(function() {
     //    event.preventDefault();
     //    $('.top-cart-content').stop().slideToggle(200);
     //});

    /*  open sidebar Cart  */
    $('#left-panel-button').panelslider();

    $('#cart-panel-button').panelslider({
      bodyClass: 'ps-active-right',
      clickClose: true,
      onOpen: function() {
        console.log('right panel open');
      }
    });

    $('#member-panel-button').panelslider({
        bodyClass: 'ps-active-member',
        clickClose: true,
        onOpen: function() {
          console.log('right panel open');
        }
    });

    var clickHandler = ('ontouchstart' in document.documentElement ? "touchstart" : "click");

    $('#content').bind(clickHandler, function(e) {
        $.panelslider.close();
    });

    $('#left-panel, #cart-panel, #member-panel-button').on('psBeforeOpen psOpen psBeforeClose psClose', function(e) {
      console.log(e.type, e.target.getAttribute('id'));
    });

})

$(function() {
    $("#menu").mmenu({
        extensions: [
            "border-full",
            "effect-slide-panels-100",
            "theme-dark"
        ],
        counters: true,
        offCanvas: {
            position: "left",
            zposition: "front"
        },
        // dragOpen: {
        //     open: true,
        // },
        navbars: [{
            position: "top",
            content: ["prev", "title"],
        }, {
            position: "bottom",
            height: 1,
            content: [
                '<a href="#/">繁體中文</a>',
                '<a href="#/">简体中文</a>',
                '<a href="#/">English</a>',
            ]
        }]
    });
    
});

$("#search-icon, .mobile-search-icon").click(function() {
    $(".search-area").fadeIn(300);
    $(".search-overlay").fadeIn(300);
    $("#search-form input").focus();
}); 
$(".search-overlay").click(function() {
    $(".search-area").fadeOut(300);
    $(".search-overlay").fadeOut(300);
});
$("#search-products .fa-remove").click(function() {
    $("#search-form input").val("");
});

// $("#search-form input").blur(function() {
//     $("#search-form").fadeOut(300)
// });


    var isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
    jQuery(window).on("load", function() {
        isTouchDevice && jQuery("#nav a.level-top").on("click", function() {
            if ($t = jQuery(this), $parent = $t.parent(), $parent.hasClass("parent")) {
                if (!$t.hasClass("menu-ready")) return jQuery("#nav a.level-top").removeClass("menu-ready"), $t.addClass("menu-ready"), !1;
                $t.removeClass("menu-ready")
            }
        }), jQuery().UItoTop()
    }), 
    jQuery(window).scroll(function() {
        jQuery(this).scrollTop() > 1 ? $("nav").addClass("sticky") : jQuery("nav").removeClass("sticky")
    }),


    /*  ToTop */
    function(e) {
        jQuery.fn.UItoTop = function(t) {
            var a = {
                    text: "",
                    min: 200,
                    inDelay: 600,
                    outDelay: 400,
                    containerID: "toTop",
                    containerHoverID: "toTopHover",
                    scrollSpeed: 300,
                    easingType: "linear"
                },
                i = e.extend(a, t),
                n = "#" + i.containerID,
                s = "#" + i.containerHoverID;
            jQuery("body").append('<a href="#" id="' + i.containerID + '">' + i.text + "</a>"), jQuery(n).hide().on("click", function() {
                return jQuery("html, body").animate({
                    scrollTop: 0
                }, i.scrollSpeed, i.easingType), jQuery("#" + i.containerHoverID, this).stop().animate({
                    opacity: 0
                }, i.inDelay, i.easingType), !1
            }).prepend('<span id="' + i.containerHoverID + '"></span>').hover(function() {
                jQuery(s, this).stop().animate({
                    opacity: 1
                }, 600, "linear")
            }, function() {
                jQuery(s, this).stop().animate({
                    opacity: 0
                }, 700, "linear")
            }), jQuery(window).scroll(function() {
                var t = e(window).scrollTop();
                "undefined" == typeof document.body.style.maxHeight && jQuery(n).css({
                    position: "absolute",
                    top: e(window).scrollTop() + e(window).height() - 50
                }), t > i.min ? jQuery(n).fadeIn(i.inDelay) : jQuery(n).fadeOut(i.Outdelay)
            })
        }
    }(jQuery),
    jQuery.extend(jQuery.easing, {
        easeInCubic: function(e, t, a, i, n) {
            return i * (t /= n) * t * t + a
        },
        easeOutCubic: function(e, t, a, i, n) {
            return i * ((t = t / n - 1) * t * t + 1) + a
        }
    }),
    /* Accordian */
    jQuery.extend(jQuery.easing, {
        easeInCubic: function(e, t, n, i, s) {
            return i * (t /= s) * t * t + n
        },
        easeOutCubic: function(e, t, n, i, s) {
            return i * ((t = t / s - 1) * t * t + 1) + n
        }
    }),
    function(e) {
        e.fn.extend({
            accordion: function() {
                return this.each(function() {})
            }
        })
    }(jQuery), jQuery(function(e) {
        e(".accordion").accordion(), e(".accordion").each(function() {
            var t = e(this).find("li.active");
            t.each(function(n) {
                e(this).children("ul").css("display", "block"), n == t.length - 1 && e(this).addClass("current")
            })
        })
    }),


    /* Responsive Nav */
    function(e) {
        e.fn.extend({
            accordion: function(t) {
                var n = {
                        accordion: "true",
                        speed: 300,
                        closedSign: "[+]",
                        openedSign: "[-]"
                    },
                    i = e.extend(n, t),
                    s = e(this);
                s.find("li").each(function() {
                    0 != e(this).find("ul").size() && (e(this).find("a:first").after("<em>" + i.closedSign + "</em>"), "#" == e(this).find("a:first").attr("href") && e(this).find("a:first").on("click", function() {
                        return !1
                    }))
                }), s.find("li em").on("click", function() {
                    0 != e(this).parent().find("ul").size() && (i.accordion && (e(this).parent().find("ul").is(":visible") || (parents = e(this).parent().parents("ul"), visible = s.find("ul:visible"), visible.each(function(t) {
                        var n = !0;
                        parents.each(function(e) {
                            return parents[e] == visible[t] ? (n = !1, !1) : void 0
                        }), n && e(this).parent().find("ul") != visible[t] && e(visible[t]).slideUp(i.speed, function() {
                            e(this).parent("li").find("em:first").html(i.closedSign)
                        })
                    }))), e(this).parent().find("ul:first").is(":visible") ? e(this).parent().find("ul:first").slideUp(i.speed, function() {
                        e(this).parent("li").find("em:first").delay(i.speed).html(i.closedSign)
                    }) : e(this).parent().find("ul:first").slideDown(i.speed, function() {
                        e(this).parent("li").find("em:first").delay(i.speed).html(i.openedSign)
                    }))
                })
            }
        })
    }(jQuery),
    function(e) {
        e.fn.extend({
                accordionNew: function() {
                    return this.each(function() {
                        function t(t, i) {
                            e(t).parent(l).siblings().removeClass(s).children(c).slideUp(r), e(t).siblings(c)[i || o]("show" == i ? r : !1, function() {
                                e(t).siblings(c).is(":visible") ? e(t).parents(l).not(n.parents()).addClass(s) : e(t).parent(l).removeClass(s), "show" == i && e(t).parents(l).not(n.parents()).addClass(s), e(t).parents().show()
                            })
                        }
                        var n = e(this),
                            i = "accordiated",
                            s = "active",
                            o = "slideToggle",
                            c = "ul, div",
                            r = "fast",
                            l = "li";
                        if (n.data(i)) return !1;
                        e.each(n.find("ul, li>div"), function() {
                            e(this).data(i, !0), e(this).hide()
                        }), e.each(n.find("em.open-close"), function() {
                            e(this).on("click", function() {
                                return void t(this, o)
                            }), e(this).bind("activate-node", function() {
                                n.find(c).not(e(this).parents()).not(e(this).siblings()).slideUp(r), t(this, "slideDown")
                            })
                        });
                        var a = location.hash ? n.find("a[href=" + location.hash + "]")[0] : n.find("li.current a")[0];
                        a && t(a, !1)
                    })
                }
            })
            // e(function() {
            //     function t() {
            //         var t = e('.navbar-collapse form[role="search"].active');
            //         t.find("input").val(""), t.removeClass("active")
            //     }
            //     e('header, .navbar-collapse form[role="search"] button[type="reset"]').on("click keyup", function(n) {
            //         console.log(n.currentTarget), (27 == n.which && e('.navbar-collapse form[role="search"]').hasClass("active") || "reset" == e(n.currentTarget).attr("type")) && t()
            //     }), e(document).on("click", '.navbar-collapse form[role="search"]:not(.active) button[type="submit"]', function(t) {
            //         t.preventDefault();
            //         var n = e(this).closest("form"),
            //             i = n.find("input");
            //         n.addClass("active"), i.focus()
            //     }), e(document).on("click", '.navbar-collapse form[role="search"].active button[type="submit"]', function(n) {
            //         n.preventDefault();
            //         var i = e(this).closest("form"),
            //             s = i.find("input");
            //         e("#showSearchTerm").text(s.val()), t()
            //     })
            // })

    }(jQuery);

    (function() {
        $("#searchclear").click(function(){
            $("#searchinput").val('');
        });
        // var searchForm = document.getElementById('searchproducts'),
        //     textInput = searchForm.Search,
        //     clearBtn = textInput.nextSibling;
        // textInput.onkeyup = function() {
        //     // Show the clear button if text input value is not empty
        //     clearBtn.style.visibility = (this.value.length) ? "visible" : "hidden";
        // };
        // // Hide the clear button on click, and reset the input value
        // clearBtn.onclick = function() {
        //     //this.style.visibility = "hidden";
        //     textInput.value = "";
        // };
    })();

